<template>
    <v-row>
        <v-col cols="12" md="6">
            <v-card style="height: 100%;">
                <v-card-title>
                    <div class="d-flex align-center flex-wrap" style="gap: 8px;">
                        <v-icon>mdi-cash-register</v-icon>
                        <span class="font-weight-medium text-uppercase text-subtitle-1">
                            Información del fondo
                        </span>
                    </div>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <dl>
                        <dt class="font-weight-bold">Institución:</dt>
                        <dd>{{ fondo.Institucion.nombre ?? '-' }}</dd>

                        <dt class="font-weight-bold mt-2">Nombre:</dt>
                        <dd>{{ fondo.nombre ?? '-' }}</dd>

                        <dt class="font-weight-bold mt-2">Monto reservado:</dt>
                        <dd>{{ toMoneyFormat(fondo.monto_establecido) }}</dd>

                        <dt class="font-weight-bold mt-2">Monto disponible:</dt>
                        <dd>{{ toMoneyFormat(fondo.monto_disponible) }}</dd>

                        <dt class="font-weight-bold mt-2">Monto ejecutado:</dt>
                        <dd>{{ toMoneyFormat(fondo.monto_establecido - fondo.monto_disponible) }}</dd>
                    </dl>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6">          
            <v-card style="height: 100%;">
                <v-card-title>
                    <div class="d-flex align-center flex-wrap" style="gap: 8px;">
                        <v-icon>mdi-note-text-outline</v-icon>
                        <span class="font-weight-medium text-uppercase text-subtitle-1">
                            Información del registro
                        </span>
                    </div>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <dl>
                        <dt class="font-weight-bold mt-2">Área solicitante:</dt>
                        <dd>{{ registro.area_solicitante ?? '-' }}</dd>

                        <dt class="font-weight-bold mt-2">Número de documento:</dt>
                        <dd>{{ registro.numero_documento ?? '-' }}</dd>

                        <dt class="font-weight-bold mt-2">Motivo:</dt>
                        <dd>{{ registro.justificacion ?? '-' }}</dd>

                        <dt class="font-weight-bold mt-2">Proveedor:</dt>
                        <dd>{{ registro.nombre_proveedor ?? '-' }}</dd>

                        <dt class="font-weight-bold mt-2">Monto:</dt>
                        <dd>{{ toMoneyFormat(registro.monto) }}</dd>

                        <dt class="font-weight-bold mt-2">Monto retenido:</dt>
                        <dd>{{ toMoneyFormat(registro.monto_retenido) }}</dd>
                    </dl>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { toMoneyFormat } from '@/utils/data';

export default {
    name: 'InformacionRegistro',
    props: {
        fondo: { type: Object },
        registro: { type: Object },
    },
    methods: {
        toMoneyFormat,
    },
}
</script>