<template>
    <v-container>
        <v-card>
            <v-card-title>
                <div class="d-flex align-center" style="gap: 16px;">
                    <NavButton />
                    <h2 class="text-h6 text-uppercase font-weight-bold">Documentos de registro de compra en línea</h2>
                </div>
            </v-card-title>

            <v-divider />

            <v-card-text>
                <template v-if="informacionRegistro.isLoading">
                    <div class="d-flex align-center" style="gap: 16px;">
                        <v-progress-circular indeterminate :size="25" :width="3" />
                        <p class="mb-0">Cargando... </p>
                    </div>
                </template>
                <template v-else>
                    <EmptyComponent v-if="!idRegistro" empty text="El registro no es válido" icon="mdi-alert-circle"  />
                    <EmptyComponent v-else :empty="!informacionRegistro.data">

                        <InformacionRegistro :fondo="fondo" :registro="registro" />
                        <ArbolDocumentos :categorias="categorias" />
                    </EmptyComponent>
                </template>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import InformacionRegistro from '../components/compraEnLinea/InformacionRegistro.vue';
import ArbolDocumentos from '../components/compraEnLinea/ArbolDocumentos.vue';
import EmptyComponent from '@/components/utils/EmptyComponent.vue';
import NavButton from '@/components/utils/NavButton.vue';
import { toMoneyFormat } from '@/utils/data';

export default {
    components: { NavButton, EmptyComponent, InformacionRegistro, ArbolDocumentos },
    data: () => ({
        informacionRegistro: createLoadable(null),
        idRegistro: null,
    }),
    computed: {
        fondo() {
            return this.informacionRegistro.data?.fondo;
        },
        registro() {
            return this.informacionRegistro.data;
        },
        categorias() {
            return this.informacionRegistro.data?.categorias_documentos ?? [];
        },
    },
    methods: {
        toMoneyFormat,
        async cargarInformacionDeRegistro() {
            toggleLoadable(this.informacionRegistro);
            const { data } = await this.services.ReporteriaCompraEnLinea.cargarDocumentosRegistro(this.idRegistro);
            setLoadableResponse(this.informacionRegistro, data);
        },
    },
    created() {
        const idRegistroUrl = this.$route.params.id_registro;
        
        if (isFinite(idRegistroUrl)) {
            this.idRegistro = idRegistroUrl;
            this.cargarInformacionDeRegistro();
        }
    },
}
</script>