<template>
    <div>
        <div class="d-flex align-center flex-wrap mt-8" style="gap: 4px">
            <v-icon color="secondary">mdi-file</v-icon>
            <h6 class="text-uppercase font-weight-bold text-subtitle-1 secondary--text">Documentos</h6>
        </div>
        <v-divider />

        <v-expansion-panels class="mt-4">
            <v-expansion-panel v-for="categoria in categorias" :key="categoria.id">
              <v-expansion-panel-header class="px-4 py-4">
                {{ categoria.nombre }}
              </v-expansion-panel-header>
              <v-divider />
              <v-expansion-panel-content class="px-4 py-4">
                <DataTableComponent 
                    :headers="headers"
                    :items="asignarNombreDocumentos(categoria.documentos)"
                    :tiene_paginacion="false"
                    dense
                    no-gutters
                >
                    <template v-slot:item.acciones="{ item }">
                        <v-btn @click.stop="visualizarDocumento(item)" icon>
                            <v-icon color="secondary">mdi-eye</v-icon>
                        </v-btn>
                    </template>
                </DataTableComponent>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <PdfModal
            :filename="nombreDocumento"
            :is-open="modalVisualizarDocumentoAbierta"
            :source-loadable="visualizacionDocumento"
            @on-visibility-change="cerrarModalVerDocumento"
        />
    </div>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable, isResponseSuccesful } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import PdfModal from '@/components/PdfModal.vue';

export default {
    name: 'ArbolDocumentos',
    components: { DataTableComponent, PdfModal },
    props: {
        categorias: { type: Array }
    },
    data: () => ({
        documentoActivo: null,
        visualizacionDocumento: createLoadable(null),
        modalVisualizarDocumentoAbierta: false,
    }),
    computed: {
        headers() {
            return [
                { align: 'center', sortable: false, text: 'Nombre', value: 'nombre', width: '80%' },
                { align: 'center', sortable: false, text: 'Ver documento', value: 'acciones' },
            ]
        },
        nombreDocumento() {
            return this.documentoActivo?.nombre ?? 'documento';
        },
    },
    methods: {
        asignarNombreDocumentos(documentos) {
            return documentos.map((documento, index) => ({ ...documento, nombre: `Documento ${index + 1}` }));
        },
        cerrarModalVerDocumento(visible) {
            this.modalVisualizarDocumentoAbierta = visible;
        },
        async visualizarDocumento(documento) {
            this.documentoActivo = { ...documento };

            this.modalVisualizarDocumentoAbierta = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.ReporteriaCompraEnLinea.visualizarDocumento(documento.id);
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
    },
}
</script>
<style scoped>
:deep(.v-expansion-panel-content__wrap) {
    padding: 0 !important;
}
</style>